<template>
  <div class="app-calendar border">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar
            ref="refCalendarSidebar"
            :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
            :is-google-access-token-valid="isGoogleAccessTokenValid"
            @get-google-events="get_google_events"
            @get-microsoft-events="get_microsoft_events"
            @calendar-sign-out="calendarSignOut"
            @toggle-add-event-form="toggleAddEventForm"
          />
        </div>

        <!-- Calendar -->
        <div class="calendar col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <!-- Public Google Calendar Checkbox -->
            <b-row class="py-1 px-3">
              <b-form-checkbox
                v-if="false"
                v-model="calendarType"
                class="w-48 h-12"
                switch
                inline
                color="primary"
              >
                <span
                  class="text-xl py-auto"
                >Public Calendar</span>
              </b-form-checkbox>
            </b-row>

            <div class="calendar-container card-body pb-0">
              <b-overlay :show="isLoading">
                <full-calendar
                  v-if="!calendarType"
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar-custom"
                />

                <iframe
                  v-else
                  src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FSingapore&amp;src=Y19ubW9kMTl0YjI1ODRndGR2bXBzdHVxajZyc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F6BF26&amp;hl=en_GB&amp;title=Events"
                  class="w-100"
                  :height="isMobile ? `${getScreenHeight() * 0.65}px` : '600px;'"
                  frameborder="0"
                  scrolling="no"
                />
              </b-overlay>

            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="add_event"
          @update-event="updateEvent"
        />
      </div>

      <b-modal
        v-model="showActionsModal"
        size="lg"
        centered
        hide-footer
        :title="`Event`"
      >
        <div class="vx-col w-full">
          <div class="mx-1">
            <div v-if="event.id">
              <b-row>
                <b-col class="mb-1">
                  <div class="font-weight-bolder">
                    Title / Position
                  </div>
                  <div class="pl-2">
                    {{ event.title }}
                  </div>
                </b-col>
                <b-col class="mb-1">
                  <div class="font-weight-bolder">
                    Calendar Category
                  </div>
                  <div class="pl-2">
                    {{ event.extendedProps.calendar }}
                  </div>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col>
                  <div class="font-weight-bolder">
                    Description
                  </div>
                  <div class="pl-2">
                    {{ event.extendedProps.description }}
                  </div>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col>
                  <div class="font-weight-bolder">
                    Start Date
                  </div>
                  <div class="pl-2">
                    {{ $formatDate(event.start) }}
                  </div>
                </b-col>
                <b-col>
                  <div class="font-weight-bolder">
                    End Date
                  </div>
                  <div class="pl-2">
                    {{ $formatDate(event.extendedProps.end_date) }}
                    {{event.end}}
                  </div>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col>
                  <div class="font-weight-bolder">
                    Start Time
                  </div>
                  <div class="pl-2">
                    {{ $formatTime(event.extendedProps.start_time) }}
                  </div>
                </b-col>
                <b-col>
                  <div class="font-weight-bolder">
                    End Time
                  </div>
                  <div class="pl-2">
                    {{ $formatTime(event.extendedProps.end_time) }}
                  </div>
                </b-col>
              </b-row>
            </div>

            <div v-else>
              <b-row class="mb-1">
                <b-col>
                  <div class="font-weight-bolder">
                    Start Date
                  </div>
                  <div class="pl-2">
                    {{ $formatDatetime(event.start) }}
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-row class="mt-2 justify-content-end">
              <b-col
                v-if="event.id && event.extendedProps.source_url && event.extendedProps.calendar === 'Personal'"
                cols="4"
              >
                <b-button
                  class="mr-3 mb-2 w-100"
                  variant="info"
                  @click="openInNewTab(event.extendedProps.source_url)"
                >View Event</b-button>
              </b-col>
              <b-col
                v-else-if="event.id"
                cols="4"
              >
                <b-button
                  v-if="
                    $store.state.auth.ActiveUser.account_type !== $AccountTypes.APPLICANT
                      && $store.state.auth.ActiveUser.account_type !== $AccountTypes.EMPLOYER
                  "
                  class="mr-3 mb-2 w-100"
                  variant="dark"
                  @click="editEvent"
                >Edit Event</b-button>
              </b-col>

            </b-row>
          </div>
        </div>
      </b-modal>
      <ProfileCompletionForm
        :activate-popup="popupProfileQuestions"
        :hide-header="true"
        @open-completion-form="activateTypeForm"
      />
    </b-overlay>
    
  </div>
</template>

<script>

import { completionMixins } from '@/components/mixins/CompletionMixins'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

import {
  BFormCheckbox,
  BRow,
} from 'bootstrap-vue'

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import useCalendarSidebar from './calendar-sidebar/useCalendarSidebar'

export default {
  components: {
    BFormCheckbox,
    BRow,
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  mixins: [completionMixins, onResizeMixins],
  setup() {
    const isLoading = false
    const calendarType = false

    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,

      // custom
      showActionsModal,
    } = useCalendar()

    fetchEvents()

    calendarOptions.value.height = 'auto'
    calendarOptions.value.contentHeight = '10px'
    // calendarOptions['value']['headerToolbar'] = {
    //   left: "prev,next today",
    //   center: "title",
    // }

    //! used to filter out unticked calendar on sidebar
    const allEvents = [] // kee all events so when reticking calendar will show them without fetching it again
    const filteredEvents = []
    const {
      selectedCalendars,
    } = useCalendarSidebar()

    const isGoogleAccessTokenValid = false

    const events_data = []
    return {
      isLoading,
      calendarType,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,

      // custom
      showActionsModal,

      allEvents,
      filteredEvents,
      selectedCalendars,

      isGoogleAccessTokenValid,
      events_data,
    }
  },
  watch: {
    selectedCalendars(val) {
      //! used to filter out unticked calendar on sidebar
      this.filteredEvents = this.allEvents.filter(event => this.selectedCalendars.includes(event.extendedProps.calendar))
      this.calendarOptions.events = this.filteredEvents
    },
  },
  created() {
    // this.get_events()

    this.populateMockData()
    
    // if (localStorage.getItem('googleAccessToken') !== null) { // check if user is logged in microsoft
    //   this.get_google_events()
    // } else {
    //   this.isGoogleAccessTokenValid = false
    // }

    // if (localStorage.getItem('microsoftAccessToken') !== null) { // check if user is logged in microsoft
    //   this.get_microsoft_events()
    // }
  },
  methods: {
    populateMockData() {
      this.events_data = [
        {
          event_name: 'Rethinking Product \nLaunch',
          event_image: require('@/assets/images/placeholder/DSG/rethinking.jpg'),
          calendar: '25 August 2023 1:00 PM - 6:30 PM​',
          start_date: '2023-08-25',
          start_time: '2023-08-25 13:00',
          end_date: '2023-08-25',
          end_time: '2023-08-25 18:30',
          event_type: 'Event | Workshops',
          redirect_url: 'https://www.eventbrite.sg/e/rethinking-product-launch-tickets-689742868487',
          class_names: '',
        },
        {
          event_name: 'Singapore Design Week:\nDesign Crawl – SG Mark Winners Sharing',
          // event_image: require('@/assets/images/placeholder/DSG/dsg-event-transparent.png'),
          event_image: require('@/assets/images/placeholder/DSG/design-crawl.png'),
          calendar: '21 September 2023 6:30 PM - 8:30 PM​',
          start_date: '2023-09-21',
          start_time: '2023-09-21 18:30',
          end_date: '2023-09-21',
          end_time: '2023-09-21 20:30',
          event_type: 'Event | Workshops',
          redirect_url: 'https://www.eventbrite.sg/e/singapore-design-week-tickets-687644291587',
          class_names: 'design-crawl',
        },
      ]

      this.events_data.forEach(event => {
        this.calendarOptions.events.push(
          {
            id: null,
            title: event.event_name,
            start: new Date(event.start_date),
            end: new Date(event.end_date),
            allDay: false,
            url: '',
            extendedProps: {
              calendar: 'DSG',
              // calendar: event.company_name,
              guests: [],
              location: '',
              description: '-',
              start_date: new Date(event.start_date),
              end_date: new Date(event.end_date),
              start_time: new Date(event.start_time),
              end_time: new Date(event.end_time),
            },
          },
        )
      })


    },
    editEvent() {
      this.showActionsModal = false
      this.isEventHandlerSidebarActive = true
    },
    get_events() {
      this.isLoading = true
      this.events_data = []
      this.event_groups = []

      const query = `?params=${JSON.stringify({
        enablePaginate: false,
      })}`
      this.$http
        .get(`/api/events${query}`)
        .then(response => {
          const { events } = response.data

          // const events = [
          //   message[1],
          // ]

          events.forEach(event => {
            const start_date = new Date(event.start_date)
            const end_date = new Date(event.end_date)

            // start_date = new Date(`${start_date.getFullYear()}-${start_date.getMonth() +1 -1}-${start_date.getDate() -1}} 12:00:00`)
            // console.log(start_date)

            this.calendarOptions.events.push(
              {
                id: event.id,
                title: event.name,
                start: start_date,
                end: end_date,
                allDay: false,
                url: '',
                extendedProps: {
                  calendar: 'DSG',
                  // calendar: event.company_name,
                  guests: [],
                  location: '',
                  description: event.description ? event.description : '-',

                  start_date,
                  end_date,
                  start_time: event.start_time,
                  end_time: event.end_time,
                },
              },
            )
          })

          this.allEvents = this.calendarOptions.events // used for filter
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })

      this.isLoading = false
    },
    add_event(event) {
      let start_time = this.createDateFromTime(event.start_time)
      let end_time = this.createDateFromTime(event.end_time)

      start_time = new Date(start_time.toISOString())
      end_time = new Date(end_time.toISOString())

      this.isLoading = true

      const form_data = {
        name: event.title,
        start_date: event.start,
        end_date: event.end,
        start_time,
        end_time,
        description: event.extendedProps.description,
      }
      this.$http
        .post('/api/event', form_data)
        .then(response => {
          if (response.data.success) {
            const { events } = response.data
            this.isLoading = false
            this.get_events()
          }
        })
    },

    createDateFromTime(time) {
      // Create a new Date object with the current time.
      const now = new Date()

      // Set the hours and minutes of the Date object to the specified time.
      now.setHours(time.split(':')[0])
      now.setMinutes(time.split(':')[1])

      // Set the seconds to 0.
      now.setSeconds(0)

      // if (now.getHours() < 12) {
      //   now.setMeridiem("AM");
      // } else {
      //   now.setMeridiem("PM");
      // }

      // Return the Date object.
      return now
    },

    handleGoogleEvents(events) {
      events.forEach(event => {
        const guests = []
        // event.attendees.forEach(attendee => {
        //   guests.push(attendee.email)
        // })

        this.calendarOptions.events.push(
          {
            id: event.id,
            title: event.summary,
            start: event.start.date,
            end: event.end.date,
            allDay: false,
            url: '',
            extendedProps: {
              calendar: 'Personal',
              guests,
              location: '',
              description: '',
              source_url: event.htmlLink,
            },
          },
        )
      })
    },
    get_google_events() {
      this.isLoading = true
      const guests = []

      this.$http
        .get('/api/events-google')
        .then(response => {
          const { events } = response.data

          events.forEach(event => {
            this.calendarOptions.events.push(
              {
                id: event.id,
                title: event.summary,
                start: event.start.date,
                end: event.end.date,
                allDay: false,
                url: '',
                extendedProps: {
                  calendar: 'Personal',
                  guests,
                  location: '',
                  description: event.description ? event.description : '-',
                  source_url: event.htmlLink,
                  source_from: 'google',
                },
              },
            )
          })

          this.allEvents = this.calendarOptions.events // used for filter

          this.isGoogleAccessTokenValid = true

          this.refreshSelectedCalendar() // to prevent event from showing if 'Personal' is not selected
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'An Error Occured', 'Unable to load calendar events from Microsoft, Please try again later')

          this.isGoogleAccessTokenValid = false
          localStorage.removeItem('googleAccessToken')
          this.$router.go()
        })

      this.isLoading = false
    },

    get_microsoft_events() {
      this.isLoading = true

      this.$http
        .get('/api/events-microsoft')
        .then(response => {
          const { events } = response.data

          events.forEach(event => {
            this.calendarOptions.events.push(
              {
                id: event.id,
                title: event.subject,
                start: this.$formatDate(event.start.dateTime),
                end: this.$formatDate(event.end.dateTime),
                allDay: event.isAllDay,
                url: '',
                extendedProps: {
                  calendar: 'Personal',
                  guests: [],
                  location: '',
                  description: '-',
                  source_url: event.webLink,
                  source_from: 'microsoft',
                },
              },
            )
          })

          this.allEvents = this.calendarOptions.events // used for filter
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Unable to load calendar events from Microsoft, Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })

      this.isLoading = false
    },

    openInNewTab(url) {
      window.open(url, '_blank')
    },

    calendarSignOut(from) { // remove events from allEvents
      let events = []
      this.allEvents = this.allEvents.filter(event => event.extendedProps.source_from !== from)

      events = this.allEvents.filter(event => // sync it with selectedCalendars
        this.selectedCalendars.includes(event.extendedProps.calendar))

      this.calendarOptions.events = events

      if (from === 'google') { // to make sign in button appear
        this.isGoogleAccessTokenValid = false
      }
    },

    getScreenHeight() {
      return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    },

    refreshSelectedCalendar() { // to prevent event from showing if 'Personal' is not selected
      const tempSelectedCaledars = this.$refs.refCalendarSidebar.selectedCalendars
      this.$refs.refCalendarSidebar.selectedCalendars = []
      setTimeout(() => {
        this.$refs.refCalendarSidebar.selectedCalendars = tempSelectedCaledars
      }, 100)
    },
    toggleAddEventForm() {
      this.isEventHandlerSidebarActive = !this.isEventHandlerSidebarActive
      this.$refs.refCalendarSidebar.isEventHandlerSidebarActive = true
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.calendar {
  height: auto;
}
.calendar-container {
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.full-calendar-custom {
  // background-color: #000;
  color: #000;
  font-style: bold;;
  height: 50vh;
}
</style>
